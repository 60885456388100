import axios from 'axios'
import formData from 'form-data'
const path = [window.location.origin];
export const baseURL = path.join('/') + '/'
export const request = axios.create({
    baseURL,
    withCredentials: true,
})
export const navigate = (endpoint: string) => {
    window.location.href = `${baseURL}${endpoint}`
}
export const pageTitle = (name: string) => {
    const title = document.querySelector('title')
    if (!title) return;
    title.innerHTML = name
}
export { formData }